import React from "react";
import styled from "styled-components";
import { graphql } from "gatsby";
import { DefaultLayout } from "../layouts";
import { Header, Background, PageWrapper, Section } from "../components";
import { white } from "../utils/colors";

const Content = styled.div``;

export default ({ data }) => {

  const post = data.markdownRemark;
  return (
    <DefaultLayout title={post.frontmatter.title}>
      <Background image={data.logo.publicURL}>
          <Header color={white}>{post.frontmatter.title}</Header>
      </Background>
      <PageWrapper>
        <Section>
          <Content dangerouslySetInnerHTML={{ __html: post.html }} />
        </Section>
      </PageWrapper>
    </DefaultLayout>
  );
};

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
      }
    }
    logo: file(name: { eq: "about-us" }) {
      publicURL
    }
  }
`;
